.btn-up {
    padding: 0.5rem 0.6rem;
    background-color: $spoor-brown;
    transition: background-color .3s;
    color: $white;
    border-radius: 50%;
    position: absolute;
    bottom: 1rem;
    z-index: 2;
    right: 1rem;
    height: 2.5rem;
    width: 2.5rem;
    &:hover {
        background-color: darken($spoor-brown, 10);
    }
}

.btn-full {
    width: 100%;
    display: block;
    padding: 1.5rem 1rem;
}

button {
    cursor: pointer;
    &.btn {
        font-weight: bold;
    }
}
