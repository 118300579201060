// Century Gothic Regular
@font-face {
    font-family: 'CenturyGothic';
    src: url($centurygothic-font-path + '/CenturyGothicRegular.woff') format('woff'), /* Pretty Modern Browsers */
    url($centurygothic-font-path + '/CenturyGothicRegular.ttf') format('truetype'), /* Safari, Android, iOS */
}

// Century Gothic Regular
@font-face {
    font-family: 'CenturyGothicBold';
    src: url($centurygothicbold-font-path + '/CenturyGothicBold.woff') format('woff'), /* Pretty Modern Browsers */
    url($centurygothicbold-font-path + '/CenturyGothicBold.ttf') format('truetype'), /* Safari, Android, iOS */
}

body,
html {
    font-family: $body-font;
    font-size: 16px;
    color: $body-color;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
    font-family: $heading-font;
    letter-spacing: 3px;
    color: $heading-color;
}

h2 {
    font-size: 1.5rem;
    margin-top: 2rem;
}

h3 {
    font-size: 1.2rem;
    letter-spacing: 1px;
    margin: 2rem 0 0.5rem;
}
