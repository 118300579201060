.footer-link-list {
    list-style: none;
    margin: 1rem -0.5rem;
    padding: 0;
    @include clearfix();
    li {
        float: left;
        margin: 0 0.5rem;
        &:after {
            content: '|';
            margin-left: 0.5rem;
        }
        &:last-of-type {
            &:after {
                display: none;
            }
        }
    }
}
