.page-header {
    margin-bottom: 4rem;
    @include media-breakpoint-down('md') {
        overflow: hidden; //Hide overflow to not allow the background illustrations to add to page width;
    }
    .right-img-block {
        padding-left: 6%;
        margin-top: -12rem;
        overflow: hidden;
        @include media-breakpoint-up('md') {
            order: 2;
        }
        @include media-breakpoint-down('md') {
            margin-top: -1rem;
            padding-left: 0;
        }
        @include media-breakpoint-down('sm') {
            margin-top: -1px;
        }
    }
    .left-img-block {
        text-align: center;
        margin-top: -5rem;
        z-index: 3;
        padding-left: 4rem;
        overflow: hidden;
        @include media-breakpoint-up('md') {
            order: 1;
        }
        @include media-breakpoint-down('md') {
            margin-top: 0;
            padding-left: 15px;
            padding-top: 2rem;
        }
        @include media-breakpoint-down('sm') {
            padding-top: 0;
        }
    }
    @include media-breakpoint-down('sm') {
        // Add spacing for fixed navbar
        padding-top: 3rem;
    }
}
