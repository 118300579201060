.nav {
    align-items: center;
    justify-content: center;
    color: $navigation-color;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 0.5rem;
    @include media-breakpoint-down('md') {
        margin-left: -15px;
    }
    @include media-breakpoint-down('sm') {
        margin-left: 0;
        background-color: rgba($white, 0.8);
    }
    .nav-item {
        a.active{
            color: $spoor-green;   
        }
        color: inherit;
        padding: 0 0.6rem;
        margin-bottom: 0;
        &.nav-dropdown {
            position: relative;
            @include media-breakpoint-down('sm') {
                > .nav-link:not([href]) {
                    // Hide main item on mobile (only if this one is not a link)
                    display: none;
                }
            }
            ul {
                transition: max-height 0.3s;
                max-height: 0;
                overflow: hidden;
                list-style: none;
                padding: 0;
                margin: 0;
                position: absolute;
                top: 100%;
                z-index: 20;
                background-color: $white;
                left: 0;
                right: 0;
                @include media-breakpoint-down('sm') {
                    background-color: transparent;
                    max-height: none;
                    position: static;

                }
                li {
                    margin: 0;
                }
            }
            &:hover,
            &:focus {
                ul {
                    max-height: 200px;
                }
            }
        }
        @include media-breakpoint-down('md') {
            text-align: center;
            padding: 0;
        }
    }
    .nav-link {
        color: inherit;
        // So no jump of the whole page happens on hover
        border-bottom: solid 1px transparent;
        transition: border .3s;
        padding: 0.5rem 0.6rem;
        &.active {
            color: $navigation-link-active-color;
        }
        &:hover,
        &:active,
        &:focus {
            @include media-breakpoint-up('md') {
                text-decoration: none;
                border-bottom: solid 1px;
            }
        }
    }
    .btn {
        @include media-breakpoint-down('md') {
            padding: 0.5rem;
        }
    }
    @include media-breakpoint-down('sm') {
        margin-bottom: 0;
        display: block;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 3rem;
        .nav-item {
            display: block;
            &:last-of-type {
                margin-bottom: 2rem;
            }
        }
        &.active {
            max-height: 300px;
        }
    }
}

nav {
    .menu-toggle {
        display: none;
        position: absolute;
        right: 1rem;
        top: 0;
    }
    @include media-breakpoint-down('sm') {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: $spoor-green;
        height: 3rem;
        z-index: 10;
        .menu-toggle {
            display: block;
            font-size: 2rem;
            color: $spoor-grey;
        }
    }
}
