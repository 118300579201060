.hero-img-block {
    border-radius: 0 0 100% 0;
    overflow: hidden;
    width: calc(100% + 10rem);
    max-width: 1000px;
    img {
        width: 100%;
        max-height: 470px; 
    }
}
