/*
    In chemistry, molecules are groups of atoms bonded
    together that take on distinct new properties. For
    instance, water molecules and hydrogen peroxide
    molecules have their own unique properties and
    behave quite differently, even though they’re made
    up of the same atomic elements (hydrogen and oxygen).

    ~ Brad Frost
 */

/*
    In interfaces, molecules are relatively simple groups
    of UI elements functioning together as a unit. For
    example, a form label, search input, and button can
    join together to create a search form molecule.

    ~ Brad Frost
 */

@import 'card';
@import 'header';
@import 'footer';
@import 'navigation';
@import 'logo';
@import 'navbar';
@import 'hero-img-block';
@import 'footer-link-list';
