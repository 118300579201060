.logo {
    &.brand-logo {
        position: relative;
        min-height: 20rem;
        padding: 3rem 1rem 7rem 0rem;
        z-index: 2;
        @include media-breakpoint-down('md') {
            padding: 1rem 0;
            min-height: auto;
            position: static;
            overflow: hidden;
        }
        &:before {
            content: '';
            position: absolute;
            left: -36%;
            top: 0;
            background: $spoor-grey;
            width: 136%;
            height: 100%;
            border-radius: 0 0 0 100%;
            z-index: 0;
            @include media-breakpoint-up('xl') {
                width: 166%;
                left: -66%;
            }
            @include media-breakpoint-down('md') {
                left: auto;
                right: 0;
                width: 160%;
                border-radius: 0;
            }
            @include media-breakpoint-down('sm') {
                left: -50%;
                right: -50%;
                width: 200%;
            }
        }
        img {
            width: 100%;
            z-index: 10;
            position: relative;
            @include media-breakpoint-down('md') {
                width: 100%;
            }
            @include media-breakpoint-down('sm') {
                width: 70%;
                margin-left: 15%
            }
        }
    }
}
