.card {
    background-color: $card-background-color;
    margin-bottom: $grid-gutter-width-base;
    border-radius: 0;
    &.borderless {
        border: 0; 
    }
    .card-block {
        padding: 2rem 3rem;
        @include media-breakpoint-down('sm') {
            padding: 2rem 1.5rem;
        }
    }
    .card-title {
        font-size: 1.5rem;
        text-align: center;
        font-weight: 300;
        margin-top: 1.2rem;
    }
    .card-meta{
        text-align: center;
        font-size: 1rem;
    }
    > img {
        width: 100%;
        height: auto;
    }
}

@include media-breakpoint-up('lg') {
    *:not([class*="col-"]) {
        > .card {
            .card-title {
                width: 50%;
                text-align: left;
            }
        }
    }
}
