// Fonts path
//$base-font-path: 'design/fonts/';
$base-font-path: '../fonts/';
$centurygothic-font-path: $base-font-path + 'centurygothic';
$centurygothicbold-font-path: $base-font-path + 'centurygothicbold';

// Fonts
$heading-font: 'CenturyGothicBold', sans-serif;
$body-font: 'CenturyGothic', sans-serif;
$body-color: #4e4e4e;
$heading-color: #4e4e4e;

// Base colors
$white:  #fff;
$black:  #000;
$red:    #d9534f;
$orange: #f0ad4e;
$yellow: #ffd500;
$green:  #5cb85c;
$blue:   #54575e;
$teal:   #5bc0de;
$pink:   #ff5b77;
$purple: #613d7c;

// Main
$background-color: #efefef;
$spoor-blue: $blue;
$spoor-green: #03b8af;
$spoor-brown-ligh: #b1a491;
$spoor-brown: #563f2d;
$spoor-grey: #e2e2e2;

$brand-primary: $spoor-green;

// Link
$link-color: $spoor-green;

// Page header
$page-header-background-color: $white;
$page-header-bottom-border-height: 6rem;
$page-header-mobile-menu-height: 6rem;

// Navigation
$navigation-color: #40404d;
$navigation-link-active-color: #308fbd;

// Page footer
$page-footer-background-color: #3d3d3d;
$page-footer-heading-color: $white;
$page-footer-color: #9d9d9d;
$page-footer-border-color: #757575;

// Cards
$card-background-color: transparent;
$card-heading-border-color: #f5f5f5;

// Table
$table-head-background: #fbfbfb;
$table-border-color: #f6f6f6;
$border-thickness: 1px;

// Fontawesome
$fa-font-path: '../fonts/fontawesome';

$spacer-x: 4rem;
$spacer-y: 4rem;
