.page-footer {
    padding: 0;
    padding-top: 7rem;
    margin-top: 2rem;
    overflow: hidden;
    position: relative;
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5 {
        color: $page-footer-heading-color;
        margin-bottom: 2.5rem;
    }
    a {
        color: inherit;
        &:hover {
            color: $white;
            text-decoration: none;
            border-bottom: solid 1px;
        }
    }
    .btn-up {
        color: $white;
        &:hover {
            border-bottom: 0;
        }
    }
}

.page-footer-left {
    position: relative;
    text-align: right;
    color: $white;
    z-index: 1;
    padding-top: 4rem;
    padding-right: 10rem;
    .footer-link-list {
        display: inline-block;
    }
    &:before {
        content: '';
        background-color: $spoor-brown;
        display: block;
        position: absolute;
        top: 0;
        left: -3rem;
        right: 0;
        bottom: 0;
        border-radius: 100% 0 0 0;
        z-index: -1;
    }
    @include media-breakpoint-down('sm') {
        text-align: left;
        background-color: $spoor-brown;
        padding: 2rem 1.5rem;
        margin: 0 15px;
        &:before {
            display: none;
        }
    }
}

.page-footer-right {
    order: 2;
    position: relative;
    z-index: 2;
    color: $white;
    font-weight: 600;
    padding: 0 0 3.5rem 0;
    &:before {
        content: '';
        background-color: $spoor-green;
        display: block;
        position: absolute;
        top: -6rem;
        left: -5rem;
        right: -12rem;
        bottom: 0;
        border-radius: 0 100% 0 0;
        z-index: -1;
    }
    @include media-breakpoint-down('sm') {
        background-color: $spoor-green;
        margin: 0 15px;
        padding: 2rem 1.5rem;
        order: 0;
        &:before {
            display: none;
        }
    }
}

.footer-copy {
    p {
        float: left;
    }
    .btn-up {
        color: $white;
        float: right;
    }
    .footer-copy-holder {
        margin-top: 2rem;
        padding-top: 2rem;
        border-top: solid 3px $page-footer-border-color;
    }
}
