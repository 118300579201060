a {
    color: $link-color;
    &:hover,
    &:active,
    &:focus {
        color: $link-color;
    }
    &.no-link {
        color: inherit;
        &:hover,
        &:active,
        &:focus {
            color: inherit;
            text-decoration: none;
        }
    }
}
