.navbar {
    display: block;
    padding: 0;
    .navbar-toggle {
        display: none;
    }
    @include media-breakpoint-down('sm') {
        .brand-logo {
            height: $page-header-mobile-menu-height - 0.5rem;
        }
        nav {
            position: absolute;
            top: calc(100% + 10px);
            left: 0;
            right: 0;
            background-color: rgba(255, 255, 255, 0.8);
            z-index: 5;
        }
        .navbar-toggle {
            display: block;
        }
    }
}
