.navbar-toggle {
    height: 2rem;
    width: 2.5rem;
    display: block;
    float: right;
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
    .icon-bar {
        width: 100%;
        height: 0.3rem;
        margin-bottom: 0.4rem;
        background-color: $spoor-blue;
        display: block;
    }
}
