.table {
    [class*="col-"] {
        padding-left: 0;
        padding-right: 0;
        padding: 1rem;
    }
    .row {
        margin-left: 0;
        margin-right: 0;
    }
    &.table-striped {
        border: solid $border-thickness $table-border-color;
        .row {
            border-bottom: solid $border-thickness $table-border-color;
            &:last-of-type {
                border-bottom: 0;
            }
        }
        [class*="col-"] {
            border-left: solid $border-thickness $table-border-color;
            &:first-of-type {
                border-left: 0;
            }
        }
    }
    .thead {
        background-color: $table-head-background;
        font-weight: bold;
    }
    p {
        margin: 0;
        padding: 0;
    }
}
